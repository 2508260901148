import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { markdown } from "@codemirror/lang-markdown";
import { styled } from "@mui/material";
import { material as theme } from "@uiw/codemirror-theme-material";
import CodeMirror, {
  type ReactCodeMirrorProps,
  type ReactCodeMirrorRef,
} from "@uiw/react-codemirror";
import { forwardRef, memo, useCallback, useMemo, useState } from "react";
import { Text } from "../data-display/Text";
import { Box, type BoxProps } from "../layout/Box";

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  maxHeight: "100%",
  overflow: "hidden",
  opacity: 1,
  transition: "opacity 300ms ease-in-out",
  "&.disabled": {
    opacity: 0.75,
  },
}));

export enum Languages {
  html = "html",
  javascript = "javascript",
  json = "json",
  markdown = "markdown",
}

const languageSupported = {
  [Languages.html]: {
    placeholder: "Enter your HTML",
    extension: html,
  },
  [Languages.javascript]: {
    placeholder: "Enter your Javascript",
    extension: javascript,
  },
  [Languages.json]: {
    placeholder: "Enter your JSON",
    extension: json,
    validator: (jsonString: string) => {
      try {
        const parsed = JSON.parse(jsonString);
        return !!(parsed && typeof parsed === "object");
      } catch (error) {
        return false;
      }
    },
  },
  [Languages.markdown]: {
    placeholder: "Enter your Markdown",
    extension: markdown,
  },
};

type CodeEditorProps = BoxProps & {
  disabled?: boolean;
  editorOptions?: ReactCodeMirrorProps["basicSetup"];
  error?: string;
  language?: Languages;
  onChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
};

export const CodeEditor = memo(
  forwardRef<ReactCodeMirrorRef, CodeEditorProps>(
    (
      {
        disabled,
        editorOptions = true,
        error,
        height,
        language = Languages.json,
        onChange,
        placeholder,
        readOnly,
        value,
        ...boxProps
      },
      ref
    ) => {
      const [innerError, setInnerError] = useState("");
      const extensions = useMemo(() => [languageSupported[language].extension()], [language]);
      const handleChange = useCallback(
        (editorState: string) => {
          if (!onChange) return;
          if (language !== Languages.json) {
            return onChange(editorState);
          }
          if (languageSupported.json.validator(editorState)) {
            setInnerError("");
            return onChange(editorState);
          }
          setInnerError("Invalid JSON");
        },
        [language, onChange]
      );
      return (
        <Box display="flex" flexDirection="column" {...boxProps}>
          <Wrapper className={disabled ? "disabled" : undefined} fontSize="0.8em" height={height}>
            <CodeMirror
              basicSetup={editorOptions}
              extensions={extensions}
              height={typeof height === "number" ? `${height}px` : undefined}
              onChange={handleChange}
              placeholder={placeholder || languageSupported[language].placeholder}
              readOnly={readOnly || disabled}
              ref={ref}
              theme={theme}
              value={value}
            />
          </Wrapper>
          {(error || innerError) && (
            <Text color="error" size="sm">
              {error || innerError}
            </Text>
          )}
        </Box>
      );
    }
  )
);
CodeEditor.displayName = "CodeEditor";
